(function() {
    angular.module('app').controller('LoginController', LoginController);

    LoginController.$inject = ['$state', 'DataService', 'toaster', 'AuthService'];

    function LoginController($state, DataService, toaster, AuthService) {
        var vm = this;

        vm.error = false;
        vm.credentials = {
            email   : '',
            password: ''
        };

        vm.submit = submit;

        function submit() {
            AuthService.login(vm.credentials.email, vm.credentials.password)
                .then(function(response) {
                    if (AuthService.isLoggedIn()) {
                        vm.error = false;
                        $state.go('auth.home');
                    }
                }, function(status) {
                    if(status == 401)
                        vm.error = true;
                    else vm.error = false;
                });
        }
    }

})();