(function(){
    angular.module('app').factory('BootService', BootService);

    BootService.$inject = ['$state', '$q', 'AuthService', '$loading'];

    function BootService($state, $q, AuthService, $loading) {
        var booted = false;
        var render = false;
        $loading.start('index');

        var service = {
            boot: boot,
            canRender: canRender
        };

        return service;

        /////////////////////

        function boot (next){
            return $q(function(resolve, reject) {
                if(booted)
                    reject();
                booted = true;

                AuthService.init().then(function() {
                    $loading.finish('index');
                    render = true;
                    resolve();
                }, function() {
                    if(next.name.includes('auth'))
                        $state.go('public.login');

                    $loading.finish('index');
                    render = true;
                    reject();
                });

            });
        }

        function canRender() {
            return render;
        }
    }
})();