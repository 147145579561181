(function () {
    'use strict';
    angular.module('app').filter('emptyCell', EmptyCellFilter);

    EmptyCellFilter.$inject = [];
    function EmptyCellFilter() {
        return function (value) {
            if(!value || value.length < 1)
                return '-';
            return value;
        };
    }
})();