(function() {
    'use strict';

    angular.module('app', [
        'toaster',
        'config',
        'pascalprecht.translate',
        'ui.router',
        'ui.bootstrap',
        'ngAnimate',
        'oitozero.ngSweetAlert',
        'darthwade.dwLoading',
        'angular-notification-icons',
        'angular-cache',
        'selector',
        'ui.select',
        'ngSanitize',
        'angularFileUpload',
        'chart.js',
        'uiSwitch'
    ]);
})();




