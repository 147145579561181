(function () {
    'use strict';

    angular.module('app').directive('loading', loadingDirective);

    loadingDirective.$inject = ['$loading', '$compile'];

    function loadingDirective($loading, $compile) {
        return {
            restrict: 'A',
            priority: 100,
            terminal: true,
            link: function ($scope, $element, attributes) {
                var key = createId();

                $element.attr('dw-loading', key);

                $scope.$watch(function () {
                    return attributes.loading;
                }, function (value) {
                    if (value === 'true') {
                        $loading.start(key);
                    }
                    else {
                        $loading.finish(key);
                    }
                });

                $compile($element, null, 100)($scope);

                function createId() {
                    return "loading" + Math.random().toString(16).slice(2);
                }
            }
        }
    }
})();