(function () {
    'use strict';
    angular.module('app').filter('date', dateFilter);

    function dateFilter() {
        return function (input) {
            var date = new Date(input);

            return date.getDate()+"."
                +(date.getMonth()+1)+"."
                +date.getFullYear()+" "
                +("0" + date.getHours()).slice(-2)+":"
                +("0" + date.getMinutes()).slice(-2);
        };
    }
})();