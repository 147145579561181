(function () {
    'use strict';
    angular.module('app').filter('price', PriceFilter);

    PriceFilter.$inject = ['$filter'];
    function PriceFilter($filter) {
        return function (num) {
            return +(Math.round(num + "e+2")  + "e-2");
        };
    }
})();