(function () {
    'use strict';
    angular.module('app').controller('HomeController', HomeController);
    HomeController.$inject = ['AuthService', '$interval'];

    function HomeController(AuthService, $interval) {
        var vm = this;
        vm.data = {};
        vm.conversionData = {};
        vm.loading = true;
        vm.switch = {
            1: true,
            2: true,
            3: true,
            4: true
        };

        vm.conversionStepSwitch = {
            1: true,
            2: true,
            3: true,
            4: true
        };

        vm.acquisitionSwitch = {
            1: true,
            2: true,
            3: true,
            4: true
        };

        vm.timelineStepSwitch = {
            1: true,
            2: false,
            3: false,
            4: false
        };

        vm.timelineAcquisitionSwitch = {
            1: true,
            2: true,
            3: true,
            4: true
        };

        vm.conversionChartOptions = {
            scales: {
                xAxes: [{
                    stacked: true,
                }],
                yAxes: [{
                    stacked: true
                }]
        }};

        vm.timelineChartOptions = {
            scales: {
                yAxes: [{
                    stacked: true
                }]
            },
            maintainAspectRatio: false
        };

        vm.getActiveAreaChartColors = function() {
            var result = [];
            for(var i = 0; i < 4; i++) {
                if(vm.switch[i+1]) {
                    result.push(vm.areaChartColors[i]);
                }
            }

            return result;
        };

        vm.getActiveBarChartColors = function() {
            var result = [];
            for(var i = 0; i < 4; i++) {
                if(vm.acquisitionSwitch[i+1]) {
                    result.push(vm.barChartColors[i]);
                }
            }

            return result;
        };

        vm.getActiveTimelineColors = function() {
            var result = [];
            for(var i = 0; i < 4; i++) {
                if(vm.timelineAcquisitionSwitch[i+1]) {
                    result.push(vm.barChartColors[i]);
                }
            }

            return result;
        };

        vm.areaChartColors = [
            '#03606E',  '#003178',  '#050061',  '#430678',  '#430678',  '#6E006B',  '#6E0016',
            // '#066E03',  '#007835',  '#006157',  '#1f5180',  '#001A6E',  '#037cc6',  '#037cc6',
        ];

        vm.barChartColors = [
            '#066E03',  '#007835',  '#006157',  '#1f5180',  '#001A6E',  '#037cc6',  '#037cc6',
        ];

        vm.activeAreaChartColors = vm.areaChartColors;
        vm.activeBarChartColors = vm.barChartColors;
        vm.switchMapping = {
            'Landing page' : 1,
            'Registration form' : 2,
            'Registration completed' : 3,
            'Transaction completed' : 4
        };
        vm.acquisitionSwitchMapping = {
            'SMS' : 1,
            'E-mail' : 2,
            'Google' : 3,
            'Other' : 4
        };
        vm.init = function () {
            vm.refresh();
            $interval(vm.refresh, 1000);
        };

        vm.formatTrackingData = function (rawData) {
            if(!rawData) {
                rawData = vm.rawTrackingData;
            }

            var formattedData = {
                labels: [],
                series: [],
                data: []
            };

            for(var i = 0; i < rawData.length; i++) {
                var row = rawData[i];


                if(!vm.switch[vm.switchMapping[row.name]]) {
                    continue;
                }

                if(formattedData.labels.indexOf(row.date) === -1) {
                    formattedData.labels.push(row.date);
                }

                if(formattedData.series.indexOf(row.name) === -1) {
                    formattedData.series.push(row.name)
                }
            }
            var found = false;

            for(i = 0; i < formattedData.series.length; i++) {
                var currentSeries = formattedData.series[i];
                var currentSeriesData = [];

                for(var j = 0; j < formattedData.labels.length; j++) {
                    var currentLabel = formattedData.labels[j];
                    found = 0;
                    for(var k = 0; k < rawData.length; k++) {
                        row = rawData[k];

                        if(row.date === currentLabel && row.name === currentSeries) {
                            found = row.count;
                            break;
                        }
                    }
                    currentSeriesData.push(found);
                }

                formattedData.data.push(currentSeriesData);
            }
            vm.activeAreaChartColors = vm.getActiveAreaChartColors();
            vm.data = angular.copy(formattedData)
        };

        vm.formatConversionData = function (rawData) {
            if(!rawData) {
                rawData = vm.rawConversionData;
            }

            var formattedData = {
                labels: [],
                series: [],
                data: []
            };

            for(var i = 0; i < rawData.length; i++) {
                var row = rawData[i];


                if(!vm.conversionStepSwitch[vm.switchMapping[row.name]]) {
                    continue;
                }

                if(!vm.acquisitionSwitch[vm.acquisitionSwitchMapping[row.acquisition]]) {
                    continue;
                }

                if(formattedData.labels.indexOf(row.name) === -1) {
                    formattedData.labels.push(row.name);
                }

                if(formattedData.series.indexOf(row.acquisition) === -1) {
                    formattedData.series.push(row.acquisition)
                }
            }

            var found = false;

            for(i = 0; i < formattedData.series.length; i++) {
                var currentSeries = formattedData.series[i];
                var currentSeriesData = [];

                for(var j = 0; j < formattedData.labels.length; j++) {
                    var currentLabel = formattedData.labels[j];
                    found = 0;
                    for(var k = 0; k < rawData.length; k++) {
                        row = rawData[k];

                        if(row.name === currentLabel && row.acquisition === currentSeries) {
                            found = row.count;
                            break;
                        }
                    }
                    currentSeriesData.push(found);
                }

                formattedData.data.push(currentSeriesData);
            }

            vm.activeBarChartColors = vm.getActiveBarChartColors();
            vm.conversionData = angular.copy(formattedData)
        };

        vm.formatTimelineData = function (rawData) {
            var newStep = false;

            if(Number.isInteger(rawData)) {
                newStep = rawData;
                rawData = false;
            }
            if(!rawData) {
                rawData = vm.rawTimelineData;
            }

            if(newStep) {

                vm.timelineStepSwitch = {
                    1: false,
                    2: false,
                    3: false,
                    4: false
                };

                vm.timelineStepSwitch[newStep] = true;
            }

            var formattedData = {
                labels: [],
                series: [],
                data: []
            };

            var currentStep = false;

            for(var i = 0; i < rawData.length; i++) {
                var row = rawData[i];

                if(!vm.timelineStepSwitch[vm.switchMapping[row.name]]) {
                    continue;
                } else {
                    currentStep = row.name;
                }

                if(!vm.timelineAcquisitionSwitch[vm.acquisitionSwitchMapping[row.acquisition]]) {
                    continue;
                }

                if(formattedData.labels.indexOf(row.date) === -1) {
                    formattedData.labels.push(row.date);
                }

                if(formattedData.series.indexOf(row.acquisition) === -1) {
                    formattedData.series.push(row.acquisition)
                }
            }

            var found = false;

            for(i = 0; i < formattedData.series.length; i++) {
                var currentSeries = formattedData.series[i];
                var currentSeriesData = [];

                for(var j = 0; j < formattedData.labels.length; j++) {
                    var currentLabel = formattedData.labels[j];
                    found = 0;
                    for(var k = 0; k < rawData.length; k++) {
                        row = rawData[k];

                        if(row.date === currentLabel && row.acquisition === currentSeries && currentStep === row.name) {
                            found = row.count;
                            break;
                        }
                    }
                    currentSeriesData.push(found);
                }

                formattedData.data.push(currentSeriesData);
            }

            vm.activeTimelineColors = vm.getActiveTimelineColors();
            vm.timelineData = angular.copy(formattedData)
        };

        vm.refresh = function() {
            AuthService.get('data').then(function (response) {
                vm.rawTrackingData = angular.copy(response.data.trackingData);
                vm.rawConversionData = angular.copy(response.data.conversionData);
                vm.rawTimelineData = angular.copy(response.data.conversionTimelineData);
                vm.formatTrackingData(response.data.trackingData);
                vm.formatConversionData(response.data.conversionData);
                vm.formatTimelineData(response.data.conversionTimelineData);
                vm.loading = false;


            }, function () {
                vm.data = [];
                vm.loading = false;
            });
        }
    }
})();