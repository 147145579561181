(function(){
    angular.module('app').factory('DataService', DataService);

    DataService.$inject = ['$http', '$filter', 'config', 'toaster', '$q'];

    function DataService($http, $filter, config, toaster, $q) {
        var apiBase = config.apiBase;

        var service = {
            get: get,
            post: post,
            put: put,
            del: del,
            changingRequest: changingRequest,
            toastError: toastError
        };

        return service;

        /////////////////////c

        function get(url, tokenArg) {
            var options = {
                method: 'GET',
                url: apiBase + url
            };

            if(typeof(tokenArg) !== "undefined")
                options["headers"] = {'authorization': tokenArg};
            
            return $q(function(resolve, reject) {
                $http(options).then(function(response) {
                    resolve(response);
                }, function(response) {
                    toastError(response.status, response.data);
                    reject(response.status);
                });
            });
        }
        
        function post(url, postData, tokenArg) {
            return changingRequest('POST', url, postData, tokenArg);
        }

        function put(url, postData, tokenArg) {
            return changingRequest('PUT', url, postData, tokenArg);
        }

        function del(url, tokenArg) {
            return changingRequest('DELETE', url, {}, tokenArg)
        }

        function changingRequest(method, url, postData, tokenArg) {
            var options = {
                method: method,
                url: apiBase + url,
                data: postData
            };

            if(typeof(tokenArg) !== "undefined")
                options["headers"] = {'authorization': tokenArg};


            return $q(function(resolve, reject) {
                $http(options).then(function(response) {
                    resolve(response);
                }, function(response) {
                    toastError(response.status, response.data);
                    reject(response.status);
                });
            });
        }

        function toastError (status, data) {
            switch(status) {
                case 400:
                case 404:
                    var toasterText = "";

                    if (typeof data.message === 'string')
                        toasterText = data.message;
                    else {
                        for (var key in data.message) {
                            if (!data.message.hasOwnProperty(key))
                                continue;

                            toasterText += data.message[key] + ", ";
                        }

                        toasterText = toasterText.slice(0, -2)
                    }

                    toaster.pop('warning', $filter('translate')('http.400_TOASTER_TITLE'), toasterText);
                    return true;
                case 401:
                    break;
                case 405:
                default:
                    //TODO log error on backend
                    // toaster.pop('error',  $filter('translate')('http.500_TOASTER_TITLE'), $filter('translate')('http.500_TOASTER_SUBTITLE'));
                    return true;
            }

            return false;
        }
    }
})();