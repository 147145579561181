(function(){
    angular.module('app').factory('AuthService', AuthService);

    AuthService.$inject = ['$q', '$window', '$state', '$cacheFactory', '$timeout', 'DataService', 'config', 'CacheFactory'];

    function AuthService($q, $window, $state, $cacheFactory, $timeout, DataService, config, CacheFactory) {
        var user = false;
        var jwt = false;
        var cache;

        var service = {
            login: login,
            isLoggedIn: isLoggedIn,
            get: get,
            post: post,
            put: put,
            del: del,
            getId: getId,
            getName: getName,
            getSurname: getSurname,
            getEmail: getEmail,
            init: init,
            logout: logout,
            clearCache: clearCache,
            updateData: updateData,
            getToken: getToken
        };

        return service;

        /////////////////////

        function clearCache(path) {
            $cacheFactory.get('$http').remove(config.apiBase + path);
        }

        function getToken() {
            if (user)
                return jwt.token;
        }

        function login(email, password) {
            return $q(function (resolve, reject) {
                DataService.post("login", {
                    email: email,
                    password: password
                }).then(function (response) {
                    var responseUser = response.data.user;
                    user = {
                        userId: responseUser.userId,
                        userName: responseUser.userName,
                        userSurname: responseUser.userSurname,
                        userEmail: responseUser.userEmail
                    };
                    $window.localStorage["user"] = JSON.stringify(user);
                    var responseJwt = response.data.jwt;

                    jwt = {
                        token: responseJwt.token,
                        ttl: responseJwt.ttl,
                        overlay: responseJwt.overlay
                    };

                    setNewToken(response.data.jwt.token);

                    resolve(response);
                }, function (status) {
                    reject(status);
                });
            });
        }

        function init() {
            if (!CacheFactory.get('authCache')) {
                cache = CacheFactory('authCache', {
                    storageMode: 'localStorage'
                });
            }

            return $q(function(resolve, reject) {
                var savedJwt;
                var savedUser;

                try {
                    savedJwt = JSON.parse($window.localStorage['jwt']);
                    savedUser = JSON.parse($window.localStorage["user"]);
                } catch(e){
                    clean();
                    reject();
                    return;
                }

                if((!(savedJwt && savedUser)) || (savedJwt.validUntil - now() < (5 * 1000))) {
                    clean();
                    reject();
                    return;
                }

                DataService.get('touch', savedJwt.token).then(function () {
                    loadData();
                    resolve();
                    return;

                }, function(code) {
                    if(code === 503) {
                        loadData();
                        resolve();
                        return;
                    }

                    clean();
                    reject();
                });

                function loadData() {
                    user = savedUser;
                    jwt = savedJwt;

                    var timer = ((savedJwt.validUntil - (savedJwt.overlay-1)*1000) - now());
                    setTimer(timer);
                }
            });
        }

        function isLoggedIn() {
            return (
                (user !== false)
                && (jwt !== false)
                && (jwt.validUntil > now())
            );
        }

        function getId() {
            if(!user)
                return false;
            return user.userId;
        }

        function getName() {
            if(!user || !user.userName)
                return "";
            return user.userName;
        }

        function getSurname() {
            if(!user || !user.userSurname)
                return "";
            return user.userSurname;
        }

        function getEmail() {
            if(!user || !user.userEmail)
                return "";
            return user.userEmail;
        }

        function get(url) {
            return $q(function(resolve, reject) {
                    DataService.get(url, jwt.token).then(function (response) {
                        if (typeof response.data.jwt !== 'undefined')
                            setNewToken(response.data.jwt);
                        cache.put(url, response);
                        resolve(response);
                    }, function (status) {
                        if (status == 401)
                            afterLogout();
                        reject(status);
                    });
                });
        }

        function post(url, postData) {
            return changingRequest('POST', url, postData);
        }

        function put(url, postData) {
            return changingRequest('PUT', url, postData);
        }

        function del(url) {
            return changingRequest('DELETE', url);
        }

        function changingRequest(method, url, postData) {
            return $q(function(resolve, reject) {
               DataService.changingRequest(method, url, postData, jwt.token).then(function(response) {
                   if(typeof response.data.jwt !== 'undefined')
                       setNewToken(response.data.jwt);
                   resolve(response);
               }, function(status) {
                   if(status == 401)
                       afterLogout();
                        reject();
               });
            });
        }

        function afterLogout() {
            clean();
            $state.go('public.login');
        }

        function clean() {
            $cacheFactory.get('$http').removeAll();
            jwt = false;
            user = false;
            $window.localStorage["user"] = '';
            $window.localStorage["jwt"] = '';
            CacheFactory.clearAll();
        }

        function setNewToken(token) {
            jwt.token = token;
            jwt.validUntil =  (now() + (jwt.ttl*1000));

            $window.localStorage["jwt"] = JSON.stringify({
                token: jwt.token,
                validUntil: jwt.validUntil,
                ttl: jwt.ttl,
                overlay: jwt.overlay
            });

            var timer = (jwt.ttl - jwt.overlay + 1)*1000;
            setTimer(timer);
        }

        function setTimer(t) {
            if(jwt.timer)
                $timeout.cancel(jwt.timer);

            if (t > 0) {
                jwt.timer = $timeout(function() {
                    get('touch');
                }, t);
            }
        }
        
        function logout() {
            DataService.del('login', jwt.token);
            afterLogout();
        }

        function now() {
            return ((new Date()).getTime());
        }

        function updateData(data) {
            return $q(function(resolve, reject) {
                put("user/" + user.userId, data).then(function(response) {
                    user.userName = data.name;
                    user.userSurname = data.surname;
                    user.userEmail = data.email;

                    $window.localStorage["user"] = JSON.stringify(user);
                    resolve(response);
                }, function() {
                    reject();
                });
            });
        }

    }
})();