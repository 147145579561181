(function () {
    angular.module('app').run(loadingConfig);

    loadingConfig.$inject = ['$loading', '$filter'];

    function loadingConfig($loading, $filter) {
        $loading.setDefaultOptions({
            text: $filter('translate')('LOADING'),
            spinnerOptions: {
                lines: 12,
                speed: 2.2,
                trail: 100
            }
        });
    }
})();