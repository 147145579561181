(function() {
    angular.module('app')
        .controller('AppController', AppController);

    AppController.$inject = ['config', 'BootService', 'AuthService', '$state'];

    function AppController(config, BootService, AuthService, $state) {
        var vm = this;
        vm.render = false;
        vm.config = config;
        vm.render = BootService.canRender;

        vm.getFullUserName = function() {
            return AuthService.getName() + ' ' + AuthService.getSurname();
        };

        vm.getUserId = function () {
            return AuthService.getId();
        };

        vm.logout = function() {
            AuthService.logout();
        };

        vm.layout = {
            isSidebarClosed: false, // true if you want to initialize the template with closed sidebar
            isMobile: isMobile()
        };


        vm.$state = $state;


    }

    function isMobile () {// true if the browser is a mobile device
        var check = false;
        if (/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)) {
            check = true;
        }
        return check;
    }
})();