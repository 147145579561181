(function () {
    'use strict';

    angular.module('app').factory('twoWayBinder', twoWayBinder);

    twoWayBinder.$inject = ['$parse'];

    function twoWayBinder($parse) {
        function twoWayBind($scope, remote, local) {
            var remoteSetter = $parse(remote).assign;
            var localSetter = $parse(local).assign;

            $scope.$parent.$watch(remote, function (value) {
                localSetter($scope, value);
            });

            $scope.$watch(local, function (value) {
                remoteSetter($scope, value);
            });
        }

        return {
            attach: twoWayBind
        };
    };
})();

(function () {
    'use strict';

    angular.module('app').directive('mgTable', mgTable);

    mgTable.$inject = ['$compile', '$templateCache', 'twoWayBinder'];

    function mgTable($compile, $templateCache, twoWayBinder) {
        return {
            restrict: 'A',
            priority: 90,
            terminal: true,
            scope: true,
            transclude: true,
            link: function (scope, element, attr, ctrl, trans) {
                twoWayBinder.attach(scope, attr.mgTable, 'mgTable');
                var lastFilter,
                    lastPage,
                    filtered,
                    lastData = [],
                    totalItems = 0,
                    pageSize = attr.pagesize || 10,
                    currentPage = 1,
                    pageCount = 0,
                    orderBy,
                    comparator,
                    coefficient = 1;

                var el = angular.element($templateCache.get('common/mgTable/mgTableFooter.html'));
                element.append(el);

                $compile(element, null, 90)(scope);

                scope.mgTableData = [];
                scope.pages = [];

                scope.$watch(function () {
                    return attr.filter;
                }, function () {
                    refreshData();
                });

                scope.$watch(function () {
                    var changed = !angular.equals(scope.mgTable, lastData);
                    if (changed) {
                        angular.copy(scope.mgTable, lastData);
                        currentPage = 1;
                        refreshData();
                    }

                });
                scope.showFooter = function () {
                    return scope.showPagination() || scope.empty();
                };

                scope.showPagination = function () {
                    return totalItems > pageSize;
                };

                scope.empty = function () {
                    return totalItems < 1;
                };

                scope.order = function (order) {
                    if (orderBy !== undefined && orderBy === order) {
                        coefficient = -1 * coefficient;
                    } else
                        coefficient = 1;

                    if (typeof order === 'string') {
                        comparator = function (a, b) {
                            var aNorm = comparatorNormalize(a, order);
                            var bNorm = comparatorNormalize(b, order);

                            if (aNorm < bNorm)
                                return coefficient * -1;
                            if (aNorm > bNorm)
                                return coefficient;
                            return 0;
                        }
                    } else {
                        comparator = function (a, b) {
                            return coefficient * order(a, b);
                        }
                    }

                    orderBy = order;
                    refreshData();
                };

                if(attr.orderBy) {
                    scope.order(attr.orderBy);
                }

                function comparatorNormalize(value, key) {
                    return value.hasOwnProperty(key) ? (value[key] === undefined ? -Infinity : (typeof value[key] === 'string' ? value[key].toLowerCase() : (typeof value[key] === 'boolean' ? (value[key] ? 1 : 0) : value[key]))) : -Infinity;
                }

                trans(scope, function (clone) {
                    element.append(clone);
                });

                function getPages() {
                    pageCount = Math.ceil(totalItems / pageSize);

                    var pages = [];
                    pages.push({
                        active: (currentPage !== 1),
                        target: currentPage - 1,
                        arrow: 'left'
                    });

                    if (currentPage > 2)
                        pages.push({
                            name: 1,
                            target: 1,
                            active: true
                        });

                    if (currentPage > 3)
                        pages.push({
                            name: ((currentPage == 4) ? 2 : '...'),
                            target: currentPage - 2,
                            active: true
                        });

                    if (currentPage > 1) {
                        pages.push({
                            name: currentPage - 1,
                            target: currentPage - 1,
                            active: true
                        });
                    }

                    pages.push({
                        name: currentPage,
                        current: true
                    });

                    if (currentPage < pageCount) {
                        pages.push({
                            name: currentPage + 1,
                            target: currentPage + 1,
                            active: true
                        });
                    }

                    if (pageCount - currentPage > 2)
                        pages.push({
                            name: (currentPage === pageCount - 3) ? pageCount - 1 : '...',
                            target: currentPage + 2,
                            active: true
                        });

                    if (currentPage < pageCount - 1)
                        pages.push({
                            name: pageCount,
                            active: currentPage !== pageCount,
                            target: pageCount
                        });

                    pages.push({
                        name: '>',
                        active: (currentPage !== pageCount),
                        target: currentPage + 1,
                        arrow: 'right'
                    });

                    return pages;

                };

                function refreshData() {
                    var result = [];
                    if (!scope.mgTable)
                        return result;

                    if (!attr.filter || attr.filter.length < 1) {
                        result = scope.mgTable;
                    } else {
                        var lowValue = attr.filter.toLowerCase();
                        result = scope.mgTable.filter(function (row) {
                            for (var name in row) {
                                if (row.hasOwnProperty(name))
                                    if (typeof row[name] === 'string' && row[name].toLowerCase().indexOf(lowValue) != -1)
                                        return true;
                            }
                            return false;
                        });
                    }

                    if (lastFilter !== attr.filter || filtered === undefined) {
                        lastFilter = attr.filter;
                        currentPage = 1;
                    }

                    totalItems = result.length;
                    filtered = result;

                    result = orderData(result);

                    var start = (currentPage - 1) * pageSize;
                    result = result.slice(start, start + parseInt(pageSize));
                    lastPage = currentPage;

                    scope.mgTableData = result;
                    scope.pages = getPages();
                }

                function orderData(result) {
                    if (comparator === undefined)
                        return result;

                    return result.sort(comparator);
                }

                scope.goToPage = function (page) {
                    if (!page || page < 1 || page > pageCount || page === currentPage)
                        return;
                    currentPage = page;
                    refreshData();
                };
            }
        }
    }
})();