(function () {
    'use strict';

    angular.module('app')
        .config(routerConfig);

    routerConfig.$inject = ['$stateProvider', '$urlRouterProvider', '$locationProvider'];

    function routerConfig($stateProvider,   $urlRouterProvider, $locationProvider) {
        $locationProvider.html5Mode(true);
        $urlRouterProvider
            .otherwise('/');
        $stateProvider
            .state('auth', {
                abstract: true,
                templateUrl: 'auth/auth.html'
            })
            .state('auth.home', {
                url: '/',
                templateUrl: 'auth/home/home.html',
                controller: 'HomeController as home'
            })
            .state('auth.userActivity', {
                url: '/activity',
                templateUrl: 'auth/placeholder.html'
            })
            .state('auth.retention', {
                url: '/retention',
                templateUrl: 'auth/placeholder.html'
            })
            .state('auth.remarketing', {
                url: '/remarketing',
                templateUrl: 'auth/placeholder.html'
            })
            .state('auth.placeholder', {
                url: '/placeholder',
                templateUrl: 'auth/placeholder.html'
            })
            .state('public', {
                abstract: true,
                templateUrl: 'public/public.html'
            })
            .state('public.login', {
                url: '/login',
                templateUrl: 'public/login/login.html',
                controller: 'LoginController as login'
            })
    }
})();

