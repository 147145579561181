(function(){
    angular.module('app').run(checkAuth);

    checkAuth.$inject = ['$rootScope', 'BootService', 'AuthService', '$state', '$urlRouter'];

    function checkAuth($rootScope, BootService, AuthService, $state, $urlRouter) {

        $rootScope.$on("$stateChangeStart", function (event, next, params) {
            if(BootService.canRender())
                return;

            event.preventDefault();
            BootService.boot(next).then(function() {
                $urlRouter.sync();
            }, function() {
                if (next.name.includes('auth') && !AuthService.isLoggedIn()) {
                    $state.go("public.login");
                } else {
                    $urlRouter.sync();
                }
            });
        });
    }
})();